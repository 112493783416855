import { useSubscription } from "@apollo/client";
import Badge from "@resource/atlas/badge/Badge";
import { Icon } from "@resource/atlas/icon/Icon";
import type { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCalendarEvents,
  atlasChart2,
  atlasChevronRight,
  atlasGear,
  atlasInterviewers,
  atlasJobs,
  atlasLink,
  atlasPerson,
  atlasRingPerson,
  atlasTemplate,
} from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { useLogEvent } from "analytics";
import { Events } from "analytics/types";
import { useAuthContext } from "auth/context";
import {
  displayNameForSchedulingRequestAssigneeFilter,
  useAssigneeFilterParam,
} from "client/app/(scheduling requests)/__utils";
import { GuideLogo } from "client/components/generic/misc/GuideLogo";
import { useReadPersistedFilter } from "client/components/generic/table/utils";
import { useFlags } from "client/lib/launchdarkly";
import clsx from "clsx";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import {
  OrganizationFeaturesEnum,
  SchedulingRequestAssigneeFilter,
} from "generated/graphql-codegen/graphql";
import { camelCase } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Permission, StaffRoleEnum } from "shared/auth/permissions";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

import { useLayoutContext } from "./Layout";

const SCHEDULING_REQUEST_COUNTS = gql(`
  query SchedulingRequestCounts {
    currentOrganization {
      id
      schedulingRequestCounts {
        all
        unassigned
        mine
        assignedToUser
      }
    }
  }
`);
const SCHEDULING_REQUEST_COUNTS_SUBSCRIPTION = gql(`
  subscription SchedulingRequestsCountSub {
    schedulingRequestCounts {
      all
      unassigned
      mine
      assignedToUser
    }
  }
`);

function SchedulingRequestNavItem() {
  const { data } = useQuery(SCHEDULING_REQUEST_COUNTS, {
    fetchPolicy: "network-only",
  });
  const { data: subscriptionData } = useSubscription(
    SCHEDULING_REQUEST_COUNTS_SUBSCRIPTION
  );
  const [counts, setCounts] = useState<{
    all: number;
    unassigned: number;
    mine: number;
  } | null>(null);
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);
  const isActive = router.pathname.startsWith("/scheduling-requests");
  const assigneeFilter = useAssigneeFilterParam();

  // Initial set of value; once set won't update again
  useEffect(() => {
    if (!counts && data?.currentOrganization?.schedulingRequestCounts) {
      setCounts(data.currentOrganization.schedulingRequestCounts);
    }
  }, [counts, data, subscriptionData]);
  // Update value when subscription data changes
  useEffect(() => {
    if (subscriptionData?.schedulingRequestCounts) {
      setCounts(subscriptionData.schedulingRequestCounts);
    }
  }, [subscriptionData]);

  function NestedLinkItem({
    text,
    type,
  }: {
    text: string;
    type: SchedulingRequestAssigneeFilter;
  }) {
    const count =
      counts?.[camelCase(type.toLowerCase()) as keyof typeof counts];

    return (
      <Link
        href={`/scheduling-requests?assignee=${type.toLowerCase()}`}
        data-atlas-wrapper="ListItem"
        className={clsx(
          "nav-target nav-link focus:outline-none scroll-m-[2rem]"
        )}
      >
        <ListItem
          isInteractive
          isSelected={isActive && assigneeFilter === type}
          trailingContent={count ? <Badge size="small">{count}</Badge> : null}
        >
          {text}
        </ListItem>
      </Link>
    );
  }

  return (
    <div className="">
      <ListItem
        icon={atlasCalendarEvents}
        isSubtleIcon
        isInteractive
        onClick={() => {
          setIsOpen((v) => !v);
        }}
        className={clsx(
          isOpen &&
            "group-hover:!bg-white group-focus-within:!bg-white lg:!bg-white",
          isOpen &&
            "group-hover:!text-subtle group-focus-within:!text-subtle lg:!text-subtle"
        )}
        isSelected={isActive}
        trailingContent={
          <Icon
            content={atlasChevronRight}
            className="w-5 h-5 transition-transform duration-250"
            style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
          />
        }
      >
        <span>Scheduling {formatEntity("request", { plural: true })}</span>
      </ListItem>
      <div className="text-body-md pl-7">
        {isOpen && (
          <div
            className={clsx(
              "flex flex-col",
              "h-0 group-hover:h-full  group-focus-within:h-full lg:h-full",
              "opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
            )}
          >
            <NestedLinkItem
              text={displayNameForSchedulingRequestAssigneeFilter(
                SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER
              )}
              type={SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER}
            />
            <NestedLinkItem
              text={displayNameForSchedulingRequestAssigneeFilter(
                SchedulingRequestAssigneeFilter.MINE
              )}
              type={SchedulingRequestAssigneeFilter.MINE}
            />
            <NestedLinkItem
              text={displayNameForSchedulingRequestAssigneeFilter(
                SchedulingRequestAssigneeFilter.UNASSIGNED
              )}
              type={SchedulingRequestAssigneeFilter.UNASSIGNED}
            />
            <NestedLinkItem
              text={displayNameForSchedulingRequestAssigneeFilter(
                SchedulingRequestAssigneeFilter.ALL
              )}
              type={SchedulingRequestAssigneeFilter.ALL}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function ScheduledInterviewsNavItem() {
  const router = useRouter();
  const { user } = useAuthContext();
  const [isOpen, setIsOpen] = useState(true);
  const isActive = router.pathname.startsWith("/upcoming-interviews");

  const scheduledByFilter = useReadPersistedFilter<string>("scheduledById");
  const scheduledByMe =
    !!scheduledByFilter &&
    !!user?.currentUserMembership &&
    scheduledByFilter === user.currentUserMembership.id;

  function NestedLinkItem({
    text,
    href,
    isNestedActive,
  }: {
    text: string;
    href: string;
    isNestedActive: boolean;
  }) {
    return (
      <Link
        href={href}
        data-atlas-wrapper="ListItem"
        className={clsx(
          "nav-target nav-link focus:outline-none scroll-m-[2rem]"
        )}
      >
        <ListItem isInteractive isSelected={isNestedActive}>
          {text}
        </ListItem>
      </Link>
    );
  }

  return (
    <div className="">
      <ListItem
        icon={atlasCalendarEvents}
        isSubtleIcon
        isInteractive
        onClick={() => {
          setIsOpen((v) => !v);
        }}
        className={clsx(
          isOpen &&
            "group-hover:!bg-white group-focus-within:!bg-white lg:!bg-white",
          isOpen &&
            "group-hover:!text-subtle group-focus-within:!text-subtle lg:!text-subtle"
        )}
        isSelected={isActive}
        trailingContent={
          <Icon
            content={atlasChevronRight}
            className="w-5 h-5 transition-transform duration-250"
            style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
          />
        }
      >
        <span>Upcoming interviews</span>
      </ListItem>
      <div className="text-body-md pl-7">
        {isOpen && (
          <div
            className={clsx(
              "flex flex-col",
              "h-0 group-hover:h-full  group-focus-within:h-full lg:h-full",
              "opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
            )}
          >
            <NestedLinkItem
              text="Scheduled by me"
              href={`/upcoming-interviews?filters=%7B"scheduledById"%3A"${user?.currentUserMembership?.id}"%7D`}
              isNestedActive={isActive && scheduledByMe}
            />
            <NestedLinkItem
              text="All"
              href="/upcoming-interviews"
              isNestedActive={isActive && !scheduledByMe}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function ReportsNavItem() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);
  const isActive = router.pathname.startsWith("/reports");

  function NestedLinkItem({
    text,
    href,
    isNestedActive,
  }: {
    text: string;
    href: string;
    isNestedActive: boolean;
  }) {
    return (
      <Link
        href={href}
        data-atlas-wrapper="ListItem"
        className={clsx(
          "nav-target nav-link focus:outline-none scroll-m-[2rem]"
        )}
      >
        <ListItem isInteractive isSelected={isNestedActive}>
          {text}
        </ListItem>
      </Link>
    );
  }

  return (
    <div className="">
      <ListItem
        icon={atlasChart2}
        isSubtleIcon
        isInteractive
        onClick={() => {
          setIsOpen((v) => !v);
        }}
        className={clsx(
          isOpen &&
            "group-hover:!bg-white group-focus-within:!bg-white lg:!bg-white",
          isOpen &&
            "group-hover:!text-subtle group-focus-within:!text-subtle lg:!text-subtle"
        )}
        isSelected={isActive}
        trailingContent={
          <div className="flex items-center gap-1">
            <Badge size="small" variant="primary">
              BETA
            </Badge>
            <Icon
              content={atlasChevronRight}
              className="w-5 h-5 transition-transform duration-250"
              style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
            />
          </div>
        }
      >
        <span>Reports</span>
      </ListItem>
      <div className="text-body-md pl-7">
        {isOpen && (
          <div
            className={clsx(
              "flex flex-col",
              "h-0 group-hover:h-full  group-focus-within:h-full lg:h-full",
              "opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
            )}
          >
            <NestedLinkItem
              text="Scheduling"
              href="/reports/recruiting-team"
              isNestedActive={router.pathname === "/reports/recruiting-team"}
            />
            <NestedLinkItem
              text="Interviewers"
              href="/reports/interviewers"
              isNestedActive={router.pathname === "/reports/interviewers"}
            />
            <NestedLinkItem
              text="Training"
              href="/reports/training/events"
              isNestedActive={router.pathname.startsWith("/reports/training")}
            />
            <NestedLinkItem
              text="Surveys"
              href="/reports/surveys"
              isNestedActive={router.pathname === "/reports/surveys"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

// nav link data
// -------------

type BaseNavItem = {
  hide?: () => boolean;
};

type BasicNavItem = BaseNavItem & {
  icon: AtlasIconData;
  url: string;
  analyticsMessage: Events;
  title: string;
  trailingContent?: ReactNode;
  forceRefresh?: boolean;
};

type CustomNavItem = BaseNavItem & {
  component: () => JSX.Element;
};

type NavItem = BasicNavItem | CustomNavItem;

function useNavItems() {
  const { checkRolePermissions, hasFeatureEnabled } = useAuthContext();
  const { [FeatureFlags.REPORTING]: reportingEnabled } = useFlags();

  return useMemo(
    (): NavItem[] => [
      {
        title: "Candidates",
        icon: atlasRingPerson,
        url: "/candidates",
        analyticsMessage: "NavBar Button Candidates Clicked",
        hide: () => !checkRolePermissions(Permission.GUIDE.READ),
      },
      {
        component: () => <SchedulingRequestNavItem key="scheduling-requests" />,
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: formatEntity("booking link", { plural: true, capitalize: true }),
        icon: atlasLink,
        url: "/booking-links",
        analyticsMessage: "NavBar Button Booking Links Clicked",
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        component: () => (
          <ScheduledInterviewsNavItem key="scheduled-interviews" />
        ),
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: "Interview training",
        icon: atlasInterviewers,
        url: "/interviewers/pools",
        analyticsMessage: "NavBar Button Interviewer Pools Clicked",
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.TAGS.WRITE),
      },
      {
        title: "Jobs",
        icon: atlasJobs,
        url: "/jobs",
        hide: () => !checkRolePermissions(Permission.JOB.WRITE),
        analyticsMessage: "NavBar Button Jobs Clicked",
      },
      {
        title: "Templates",
        icon: atlasTemplate,
        url: "/templates",
        hide: () =>
          !checkRolePermissions(Permission.GUIDE_TEMPLATE.WRITE) &&
          !checkRolePermissions(Permission.POST_TEMPLATE.WRITE),
        analyticsMessage: "NavBar Button Templates Clicked",
      },
      {
        component: () => <ReportsNavItem key="reports" />,
        hide: () =>
          !reportingEnabled ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: "Account settings",
        icon: atlasPerson,
        url: "/account",
        analyticsMessage: "NavBar Button Account Settings Clicked",
      },
      {
        title: "Organization settings",
        icon: atlasGear,
        url: "/settings",
        hide: () => !checkRolePermissions(Permission.ORGANIZATION.WRITE),
        analyticsMessage: "NavBar Button Organization Settings Clicked",
      },
    ],
    [checkRolePermissions, hasFeatureEnabled, reportingEnabled]
  );
}

// nav logo
// --------

function NavLogo() {
  const { checkStaffRole } = useAuthContext();
  const logEvent = useLogEvent({ component: "NavLogo" });
  const hideV2Label = checkStaffRole(StaffRoleEnum.STAFF);

  return (
    <div className="px-4 py-[1.125rem] w-[16rem]">
      <Link
        href="/"
        aria-label="Home"
        className="focus:focus-ring-0 flex flex-row items-center space-x-2"
        onClick={() => logEvent("NavBar Button Resource Logo Clicked")}
        // prevent keyboard focus to prevent issues with keyboard navigation
        // the first nav link is the same, so it doesn't break accessibility
        tabIndex={-1}
        // remove focus to prevent the nav from remaining expanded after navigation
        onPointerUp={(e) => e.currentTarget.blur()}
      >
        <GuideLogo
          showV2Label={!hideV2Label}
          logoClassName="pl-[.1875rem]"
          textClassName="transition-opacity opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
        />
      </Link>
    </div>
  );
}

// nav links
// ---------

type NavLinkProps = Omit<BasicNavItem, "hide"> & {
  hide: boolean | (() => boolean);
  isDisabled?: boolean;
  currentPath: string;
  isFirst?: boolean;
};

function NavLink({
  hide,
  isDisabled,
  currentPath,
  isFirst,
  icon,
  url,
  title,
  analyticsMessage,
  trailingContent,
  forceRefresh = false,
}: NavLinkProps) {
  const { restorePageFocus } = useLayoutContext();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const router = useRouter();
  const logEvent = useLogEvent({ component: "AppNav" });

  if ((typeof hide === "function" ? hide() : hide) && !isDisabled) return null;

  const isActive =
    url === "/" ? currentPath === url : currentPath.startsWith(url);

  function moveFocus(direction: "up" | "down") {
    if (!linkRef.current) return;
    let element: HTMLElement | null;
    if (direction === "up")
      element = linkRef.current.previousElementSibling as HTMLElement | null;
    else element = linkRef.current.nextElementSibling as HTMLElement | null;

    if (element?.classList.contains("nav-link")) {
      element?.scrollIntoView?.({ block: "nearest" });
      element?.focus?.({ preventScroll: true });
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    logEvent(analyticsMessage);
    if (forceRefresh) {
      e.preventDefault();
      const newKey = Math.random().toString(36).substring(2, 15);
      router.replace(`${url}?key=${newKey}`, undefined, { shallow: true });
    }
  };

  const listItem = (
    <ListItem
      icon={icon}
      isDisabled={isDisabled}
      isSubtleIcon
      isInteractive
      isSelected={isActive}
      onClick={() => logEvent(analyticsMessage)}
      trailingContent={trailingContent}
    >
      {title}
    </ListItem>
  );

  return isDisabled ? (
    listItem
  ) : (
    <Link
      href={url}
      ref={linkRef}
      data-atlas-wrapper="ListItem"
      className={clsx(
        "nav-target nav-link focus:outline-none scroll-m-[2rem]",
        {
          first: isFirst,
          active: isActive,
        }
      )}
      // remove focus to prevent the nav from remaining expanded after navigation
      onPointerUp={(e) => e.currentTarget.blur()}
      onClick={handleClick}
      onKeyDown={(e) => {
        // close on escape
        if (e.key === "Escape") {
          restorePageFocus();
          e.stopPropagation();
        }

        // arrow navigation
        if (
          ["ArrowUp", "ArrowDown", "j", "k"].includes(e.key) &&
          (e.target as Element | null)?.classList?.contains("nav-link")
        ) {
          moveFocus(["ArrowUp", "k"].includes(e.key) ? "up" : "down");
          e.preventDefault();
        }
      }}
    >
      {listItem}
    </Link>
  );
}

function NavLinks() {
  const navItems = useNavItems();
  const router = useRouter();
  const { user } = useAuthContext();

  return (
    <div className="pb-4 pt-2 px-2 overflow-y-auto flex-grow">
      {navItems.map((item, i) => {
        if (!("icon" in item)) {
          if (item.hide?.()) return null;

          return item.component();
        }

        const { hide, ...data } = item;
        return (
          <NavLink
            {...data}
            hide={!user || hide?.() || false}
            currentPath={router.pathname}
            key={data.url}
            isFirst={i === 0}
          />
        );
      })}
    </div>
  );
}

// app nav
// -------

function AppNav() {
  return (
    <div className="flex-shrink-0 bg-white w-[3.75rem] lg:w-[16rem] relative group">
      <div
        className={clsx(
          "absolute z-20 flex flex-col h-full overflow-hidden border-r transition-all",
          "border-[#eeedef] group-hover:border-transparent group-focus-within:border-transparent lg:group-hover:border-[#eeedef] lg:group-focus-within:border-[#eeedef]",
          "group-hover:shadow-20 group-focus-within:shadow-20 lg:group-hover:shadow-none lg:group-focus-within:shadow-none",
          "w-[3.75rem] group-hover:w-[16rem] group-focus-within:w-[16rem] lg:w-[16rem] bg-white"
        )}
      >
        <NavLogo />
        <NavLinks />
      </div>
    </div>
  );
}

export default AppNav;
